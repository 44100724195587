(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/draw-manager/assets/javascripts/draw-manager-modal.js') >= 0) return;  svs.modules.push('/components/tipsen/draw-manager/assets/javascripts/draw-manager-modal.js');
"use strict";


const {
  StickyBottom,
  StickyButtonClose,
  TipsenModal
} = svs.components.tipsen.tipsenModal;
const {
  DrawSelectorList
} = svs.components.tipsen.drawSelector;
const Paper = svs.ui.ReactPaper;
const {
  DrawSelectorHeader
} = svs.components.tipsen.drawSelector;
const DrawSelectorListStickyBottom = _ref => {
  let {
    onClose
  } = _ref;
  return React.createElement(StickyBottom, null, React.createElement(StickyButtonClose, {
    isBlock: true,
    isInverted: false,
    isTransparent: true,
    onClick: e => {
      onClose(e);
    }
  }));
};
const DrawManagerModal = _ref2 => {
  let {
    draws,
    isOpen,
    toggleDrawSelector,
    currentDrawNumber,
    productBranding,
    showBetEventsByDraw,
    hasExtraMoneyValue,
    sportTypes
  } = _ref2;
  const drawSelectorListStickyBottom = () => React.createElement(DrawSelectorListStickyBottom, {
    onClose: toggleDrawSelector
  });
  return React.createElement(TipsenModal, {
    Bottom: drawSelectorListStickyBottom,
    className: "tipsen-modal-window",
    isOpen: isOpen
  }, React.createElement("div", {
    className: "pg_draw_manager__modal_container"
  }, React.createElement(Paper, {
    branding: "sport",
    className: "pg_draw_manager__modal_header"
  }, React.createElement(DrawSelectorHeader, {
    blockClassName: "pg_draw_manager_modal",
    hasExtraMoneyValue: hasExtraMoneyValue,
    shouldDisableTitle: Boolean(sportTypes.length > 1),
    sportTypes: sportTypes
  })), React.createElement(Paper, {
    branding: "sport",
    className: "pg_draw_manager__modal_body"
  }, React.createElement(DrawSelectorList, {
    branding: productBranding,
    draws: draws,
    hasExtraMoneyValue: hasExtraMoneyValue,
    onToggleFunc: toggleDrawSelector,
    selectedDrawNumber: currentDrawNumber,
    showBetEventsByDraw: showBetEventsByDraw
  }))));
};
setGlobal('svs.components.tipsen.drawManager.DrawManagerModal', DrawManagerModal);

 })(window);